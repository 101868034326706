import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SelectFile, { arrConsist } from './SelectFile'
import TableMaterial from './TableMaterial'
import * as actions2 from '../../../reducers/form';
import { initFormTable, clearFormTable } from '../../../reducers/formTable';

const formName = 'MultiLoadMaterial'
export default function MaterialLoad() {
  const [activeStep, setActiveStep] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(initFormTable({ name: formName }))
    dispatch(actions2.formInitialize({
      start: '',
      end: '',
      file: 'test.xlsx',
      fileId: '107',
      estimate: false,
      consist: arrConsist[0].value,
      valueconsist: arrConsist[0],
      allIds: [],
      byId: {},
      column: {},
      columnNew: {},
      selectId: [],
      idProject: null,
      idFacility: null,
      listName: '',
      listArr: [{ value: "Лист1", label: "Лист1" }],
      countSelect: 10,
      multiSelect: false,
      filePByIds: {},
      filePAllIds: [],
    }, { name: formName }))
    return () => {
      dispatch(clearFormTable(formName))
      dispatch(actions2.formDestroy({ name: formName }))
    }
  }, [dispatch])
  return (<div className='App-paper'>
    {activeStep === 0 && <SelectFile formName={formName} handleNext={() => setActiveStep(1)} />}
    {activeStep === 1 && <TableMaterial formName={formName} handleBack={() => setActiveStep(0)} />}
  </div>);
}