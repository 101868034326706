// @ts-check
import React, { useState, useEffect } from 'react';
import { TablePagination, TableContainer } from '@material-ui/core';
import TablePaginationActionsLeft from './TablePaginationActionsLeft'
import NotDeleteIcon from '@material-ui/icons/Clear'
import OkDeleteIcon from '@material-ui/icons/Done'
import { IconButton, Tooltip, Typography, Checkbox, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import moment from 'moment'
import 'moment/locale/ru';
import { makeStyles } from '@material-ui/core/styles';


const useStylesPagination = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'start',
    // border:'2px solid red',
    direction: "rtl"
  },
}));

const useStyleTable = makeStyles(theme => ({
  root: {
    color: "#333"
  },
  cell: {
    color: "#333",
  }
}))

/**
 * 
 * @param {import("./types/TableCardForm").TableCardForm} param0 
 */
export default function TableCardForm({
  tableActions,
  tableHead,
  tableData,
  showHead = true,
  classes = {},
  history, selected,
  search,
  pagination = true,
  deleteMulti = false,
  arrDeleteMulti = [],
  handleChangePage,
  handleChangeRowsPerPage,
  arrHidden = [],
  goBack = false,
  firstHistoryLength,
  idName,
}) {
  const classesPagination = useStylesPagination()
  const classesTable = useStyleTable()
  const [arrDisabled, setArrDisabled] = useState([])
  const { allIds = [], byId = {}, sortBy = 'name', sortDirection = 'asc' } = tableData
  const rowCount = allIds.length
  useEffect(() => {
    const arrDis = []
    if (!tableActions.disabledDelete) return;
    tableData.allIds.forEach(key => {
      const obj = tableData.byId[key]
      if (tableActions.disabledDelete(obj)) {
        arrDis.push(obj.id)
      }
    })
    setArrDisabled(arrDis)
  }, [tableActions, tableData])
  const columns = tableHead.filter(obj => !arrHidden.includes(obj.id))
  const goBackAction = <GoBackAction goBack={goBack} history={history} firstHistoryLength={firstHistoryLength} />
  return (<React.Fragment>
    <TableContainer className={classes.container}>
      <Table size='small' stickyHeader className={classesTable.root} >
        {showHead && <TableHeadCustom
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSort={tableActions.setSort}
          handleSelectAllClick={tableActions.handleSelectAllClick}
          deleteMulti={deleteMulti}
          rowCount={rowCount}
          columns={columns}
          classes={{ ...classes, cell: classesTable.cell }}
          isDeleted={Boolean(tableActions.onDeleteRow)}
          countDeleted={arrDeleteMulti.length}
          countDisabled={arrDisabled.length}
        />}
        <TableBody>{
          allIds.map(row => {
            const obj = byId[row]
            let onClick;
            if (tableActions.onClickRow) onClick = () => { history.push(tableActions.onClickRow(obj[idName])); }
            if (tableActions.onClickFunction) onClick = () => { tableActions.onClickFunction(row, obj); }
            const rowProps = {
              key: row,
              selected: selected ? selected(obj) : undefined,
              onClick: onClick
            }
            return (<RowTableForm
              idName={idName}
              tableHead={columns}
              classes={{ ...classes, cell: classesTable.cell }}
              rowProps={rowProps}
              obj={obj}
              tableActions={tableActions}
              row={row}
              key={row}
              deleteMulti={deleteMulti}
              selected={arrDeleteMulti.includes(row)}
            />)
          })}
        </TableBody>
      </Table>
    </TableContainer>
    {
      (pagination) &&
      <TablePagination
        variant='footer'
        classes={classesPagination}
        rowsPerPageOptions={[10, 25, 50, 100, 500]}
        component="div"
        count={tableData.count || Number(search.page) * Number(search.limit)}
        rowsPerPage={Number(search.limit)}
        page={Number(search.page)}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage=''
        labelDisplayedRows={({ from, to, count }) => <Typography component={'span'} style={{ direction: 'ltr' }}>{`${from}-${to} из ${count}`}</Typography>}
        ActionsComponent={
          (props) => {
            // @ts-ignore
            return <TablePaginationActionsLeft {...props} goBack={goBackAction} />
          }
        }
      />
    }
    {!pagination && goBack && goBackAction}
  </React.Fragment>
  )
}

/**
 * @param {import("./types/TableCardForm").GoBackAction} param0 
 */
function GoBackAction({ goBack, history, firstHistoryLength }) {
  const current = history?.length
  if (!goBack) return null
  const handleBack = () => {
    history.go(firstHistoryLength.current - current - 1);
  }
  return <Button color='primary' component="span" onClick={() => handleBack()}>Назад</Button>
}

/**
 * @param {import("./types/TableCardForm").TableHeadCustom} param0 
 */
function TableHeadCustom({ sortBy, sortDirection, countDeleted, countDisabled,
  setSort, handleSelectAllClick, deleteMulti,
  rowCount, columns, classes, isDeleted
}) {
  const handleRequestSort = (sortByNew) => {
    /** @type {"desc" | "asc"} */
    let sortDirectionNew = 'desc';
    if (sortBy === sortByNew && sortDirection === 'desc') {
      sortDirectionNew = 'asc';
    }
    setSort(sortByNew, sortDirectionNew)
  };
  return <TableHead>
    <TableRow>
      {deleteMulti ? <TableCell padding='none'>
        <Checkbox
          onChange={(e) => handleSelectAllClick(e)}
          indeterminate={countDeleted > 0 && countDeleted < rowCount + countDisabled}
          checked={rowCount > 0 && rowCount === countDeleted + countDisabled}
        />
      </TableCell> : null}
      {columns.map(obj => {
        return (<TableCell
          key={obj.id}
          align={obj.align || 'inherit'}
          sortDirection={sortBy === obj.id ? sortDirection : false}
          className={classes.cell}
          style={{ width: obj.width ? obj.width : undefined }}
          padding={obj.padding ? obj.padding : undefined}
        >
          <TableSortLabel
            active={sortBy === obj.id}
            direction={sortDirection}
            onClick={() => handleRequestSort(obj.id)}
          >
            {obj.name}
          </TableSortLabel>
        </TableCell>);
      })}
      {(isDeleted && !deleteMulti) ? <TableCell align='right'></TableCell> : null}
    </TableRow>
  </TableHead>
}


function EditLink({ id, actions }) {
  const toLink = React.forwardRef((props, ref) => (<Link ref={ref} to={actions.onEditRow(id)} {...props} />))
  return (
    <Tooltip title="Edit">
      { /* @ts-ignore */}
      <IconButton aria-label="Edit" component={toLink} ><Edit /></IconButton>
    </Tooltip>
  );
}

/**
 *  * @param {import("./types/TableCardForm").RowTableFormProps} param0 
 */
function RowTableForm({ tableHead, classes, rowProps, obj, tableActions, row, deleteMulti = false, selected, idName }) {
  const [isDeleted, changeDeleted] = useState(false)
  if (isDeleted && !deleteMulti) {
    return <RowDeleted
      id={obj[idName]}
      tableHead={tableHead}
      classes={classes}
      tableActions={tableActions}
      handleDeleted={() => changeDeleted(false)}
    />
  }
  return (
    <TableRow
      classes={{ root: classes.row, selected: classes.selected }}
      selected={deleteMulti ? selected : undefined}
      style={{ cursor: rowProps.onClick ? 'pointer' : 'default' }}
      {...rowProps}
    >
      {deleteMulti &&
        <TableCell padding='none' onClick={(e) => { e.stopPropagation(); }}>
          <Checkbox
            onClick={(e) => { e.stopPropagation(); tableActions.addToArrDelete(row) }}
            checked={selected}
            disabled={tableActions.disabledDelete ? tableActions.disabledDelete(obj) : false}
          />
        </TableCell>
      }
      {tableHead.map(column => {
        return <TableCell
          key={column.id}
          className={classes.cell}
          align={column.align || 'inherit'}
          style={{ width: column.width ? column.width : undefined }}
          padding={column.padding ? column.padding : undefined}
        >
          {(column.link)
            ? <Link to={column.link(obj.id)} key={column.id} >{obj[column.id]}</Link>
            : formatColumn(column, obj)
          }
        </TableCell>
      })}
      {((tableActions.onDeleteRow && !deleteMulti) || tableActions.onEditRow) ? <TableCell align='right' padding='none' className={classes.cell}>
        {tableActions.onDeleteRow && !deleteMulti ?
          <IconButton
            aria-label="Delete"
            size='small'
            className={classes.margin}
            onClick={(e) => { e.stopPropagation(); changeDeleted(true) }}
            disabled={tableActions.disabledDelete ? tableActions.disabledDelete(obj) : false}
          ><Tooltip title="Delete"><DeleteIcon fontSize='small' /></Tooltip></IconButton>
          : null}
        {tableActions.onEditRow ? <EditLink key={row} id={row} {...this.props} /> : null}
      </TableCell> : null}
    </TableRow>
  )
}

/**
 * 
 * @param {import('./types/PageListAPI').Columns} column 
 * @param {any} obj 
 */
function formatColumn(column, obj) {
  const value = obj[column.id]
  switch (column.type) {
    case 'date': {
      if (!value) return ''
      return moment(value).format((column.formatDate) ? column.formatDate : 'LLL') || ''
    }
    case 'price':
      return Number(value).toLocaleString()
    case 'component':
      return <column.component obj={obj} />
    default:
      return value
  }
}
// потом переделать а пока так 
/**
 * 
 * @param {Object} obj
 * @param {Array.<import('./types/PageListAPI').Columns>} obj.tableHead
 * @param {*} obj.classes
 * @param {*} obj.tableActions
 * @param {*} obj.handleDeleted
 * @param {string} obj.id
 */
function RowDeleted({ tableHead, classes, tableActions, handleDeleted, id }) {
  const text = <>Вы уверенны что хотите удалить эту строку? </>
  const ok = <IconButton
    className={classes.margin}
    size='small'
    onClick={(e) => { e.stopPropagation(); tableActions.onDeleteRow(id); }}
  >
    <OkDeleteIcon fontSize='small' />
  </IconButton>
  const no = <IconButton
    size='small'
    className={classes.margin}
    onClick={(e) => { e.stopPropagation(); handleDeleted(); }}
  >
    <NotDeleteIcon fontSize='small' />
  </IconButton>
  if (tableHead.length > 1) {
    return <TableRow className={classes.row}>
      <TableCell colSpan={tableHead.length - 1} >{text}</TableCell>
      <TableCell align='right' >{ok}</TableCell>
      <TableCell align='right' style={{ width: 10 }}>{no}</TableCell>
    </TableRow>
  }
  else {
    return <TableRow className={classes.row}>
      <TableCell >{text}</TableCell>
      <TableCell align='right'>{ok}{no}</TableCell>
    </TableRow>
  }
}