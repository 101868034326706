// @ts-check
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string'
import * as actions from '../reducers/formTable';
import CustomTable2 from './TableCardForm'
import { Link } from 'react-router-dom';
import { fetchDispath } from '../functions/fetch'
import { enqueueSnackbar } from '../reducers/notifier'
import FilterArray from './FilterArray';
import { Typography, Button, } from "@material-ui/core";
import PageListColumn from './PageListFilterColumn'
const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
  },
  butt: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1
  },
  row: {
    color: '#000000',
    '&$selected': {
      color: '#aaaaaa'
    }
  },
  selected: {},
  table: {
    height: 700
  },
  cell: {
    // fontSize: '0.8rem'
  },
  pagination: {},
  container: {
    height: '65vh'
  }
}));
/**
 * @typedef {import("./types/PageListAPI").Columns} Columns
 */


/** 
 * @param {import("./types/PageListAPI").PageListAPI} param0 
 */
export default function PageListAPI({
  classes = {},
  name,
  columns,
  createItem = false,
  deleteItem = false,
  idName = 'id',
  tableProps,
  click = true,
  csv = true, // пока скрывает все меню настроек, потом если надо разделить 
  headerInit = {},
  notLoad = false,
  notClear = false,
  pagination = true,
  reload = false,
  reloadFunc,
  onClickFunction,
  filterProps = [],
  title = '',
  deleteMulti = false,
  deleteMultiProps = 'arr',
  location,
  match,
  history,
  loadAPI,
  deleteAPI,
  disabledDelete,
  headerLink,
  headerObj,
  tableActions = {},
  goBack = true,
  additionalActions = []
}) {

  const classesPage = { ...useStyles(), ...classes }
  const dispatch = useDispatch();
  const searchLocation = location.search
  let obj = queryString.parse(searchLocation)
  if (pagination && !obj.limit) {
    obj.limit = '50'
    obj.offset = '0'
  }
  const [search, setSearch] = useState(obj)
  const [searchOld, setSearchOld] = useState('-1')
  const [arrDelete, setArrDelete] = useState([])
  const [arrHidden, setArrHidden] = useState([])
  const firstHistoryLength = React.useRef(history?.length || 0)
  const load = useCallback((search) => {
    dispatch(fetchDispath({
      querty: {
        ...search,
        ...loadAPI.querty
      },
      param: loadAPI.param,
      progress: true,
      request: loadAPI.request,
      transform: loadAPI.transform,
    })).then(res => {
      dispatch(actions.loadFormTable({ ...res }, { name: name, id: idName }))
    }).catch(err => console.log(err))
  }, [dispatch, name, idName,loadAPI?.querty,loadAPI?.param,loadAPI?.request,loadAPI?.transform])

  useEffect(() => {
    if (notLoad) return;
    dispatch(actions.initFormTable({ name: name, header: { ...headerInit } }))
    return () => {
      if (notClear) return;
      dispatch(actions.clearFormTable(name))
    }
  }, [dispatch, name, notLoad, notClear])
  useEffect(() => {
    if (notLoad) return;
    if (searchLocation === '' && pagination) {
      load(search)
      return;
    }
    if (searchLocation !== searchOld) {
      const searchNew = queryString.parse(searchLocation)
      setSearch(searchNew)
      setSearchOld(searchLocation)
      load(searchNew)
      return;
    }
  }, [dispatch, notLoad, searchLocation, load, searchOld, pagination, search])
  useEffect(() => {
    if (notLoad || !reload) return;
    reloadFunc()
    load(queryString.parse(searchLocation))
  }, [dispatch, notLoad, searchLocation, load, reload, reloadFunc])
  const deleteCustom = (param, body) => {
    dispatch(fetchDispath({
      param,
      body,
      progress: false,
      request: deleteAPI
    }))
      .then((res) => {
        let msg = {}
        if (res) {
          load(search)
          msg = {
            message: `success`,
            options: { variant: 'success' }
          }
        }
        else {
          msg = {
            message: `not deleted`,
            options: { variant: 'error' }
          }
        }
        dispatch(enqueueSnackbar(msg))
      })
  }
  const handleDeleteOne = (id) => deleteCustom({ id: id })
  const editFilterValue = (field, value) => {
    let searchNew = {
      ...search,
      limit: '50',
      offset: '0',
      [field]: value
    };
    setSearch(searchNew)
    history.push(`?${queryString.stringify(searchNew)}`)
  }
  const addToArrDelete = (id) => {
    setArrDelete(old => {
      if (old.includes(id)) {
        return old.filter(e => id !== e)
      }
      return [...old, id]
    })
  }
  const handleChangePage = (event, pageNew) => {
    let searchQuery = queryString.parse(searchLocation)
    const { limit, offset } = search
    const page = Number(offset) / Number(limit)
    if (page !== pageNew && event) {
      searchQuery.offset = (Number(limit) * pageNew).toString()
      if (typeof searchQuery.limit === 'undefined') searchQuery.limit = limit
      history.push(`?${queryString.stringify(searchQuery)}`)
      window.scrollTo(0, 0)
    }
  };
  const handleChangeRowsPerPage = event => {
    let searchQuery = queryString.parse(searchLocation)
    if (Number(searchQuery.limit) !== Number(event.target.value)) {
      searchQuery.limit = event.target.value
      searchQuery.offset = '0'
      history.push(`?${queryString.stringify(searchQuery)}`)
    }
  };
  const toLinkAdd = React.forwardRef((props, ref) => (
    <Link ref={ref} to={`${match.url}/add`} {...props} />
  ));
  const hideColumn = (id, add) => {
    if (add) {
      setArrHidden([...arrHidden, id])
    }
    else {
      setArrHidden(arrHidden.filter(e => id !== e))
    }
  }
  return (
    <React.Fragment>
      {title !== '' && <Typography variant='h6'>{title}</Typography>}
      <div className={classesPage.header}>
        {createItem && <Button component={toLinkAdd} color="primary" >Добавить</Button>}
        {headerLink}
        <div className={classesPage.grow} />
        {deleteMulti && <ButtonDeleteMulti
          classes={classesPage}
          deleteCustom={deleteCustom}
          deleteMultiProps={deleteMultiProps}
          arrDelete={arrDelete}
          idName={idName}
          name={name}
        />}
        {csv && <PageListColumn
          csv={csv}
          columns={columns}
          arrHidden={arrHidden}
          hideColumn={hideColumn}
          additionalActions={additionalActions}
          name={name}
        />}
      </div>
      {headerObj}
      {filterProps.length > 0 && <FilterArray
        change={editFilterValue}
        arr={filterProps}
        name={name}
      />}
      <PageListAPITable
        idName={idName}
        classesPage={classesPage}
        deleteItem={deleteItem}
        handleDeleteOne={handleDeleteOne}
        click={click}
        onClickFunction={onClickFunction}
        disabledDelete={disabledDelete}
        addToArrDelete={addToArrDelete}
        tableActions={tableActions}
        columns={columns}
        history={history}
        search={search}
        deleteMulti={deleteMulti}
        arrDelete={arrDelete}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        arrHidden={arrHidden}
        goBack={goBack}
        firstHistoryLength={firstHistoryLength}
        tableProps={tableProps}
        setArrDelete={(arr) => setArrDelete(arr)}
        name={name}
      />
    </React.Fragment>
  )
}

function ButtonDeleteMulti({ classes, deleteCustom, deleteMultiProps, arrDelete, idName, name }) {
  const byId = useSelector(state => state.formTable[name]?.byId || {})
  const handleDeleteMulti = () => {
    deleteCustom(undefined, {
      [deleteMultiProps]: arrDelete.map(id => byId[id][idName])
    })
  }
  return <Button
    onClick={handleDeleteMulti}
    color="primary"
    className={classes.butt}
    disabled={arrDelete.length === 0}>
    Удалить
  </Button>
}

/** 
 * @param {import("./types/PageListAPI").PageListAPITable} param0 
 */
function PageListAPITable({
  idName,
  classesPage,
  deleteItem,
  handleDeleteOne,
  click,
  onClickFunction,
  disabledDelete,
  addToArrDelete,
  tableActions,
  columns,
  history,
  search,
  deleteMulti,
  arrDelete,
  handleChangePage,
  handleChangeRowsPerPage,
  arrHidden,
  goBack,
  firstHistoryLength,
  tableProps,
  setArrDelete,
  name
}) {
  const formTable = useSelector(state => state.formTable[name] || state.formTable.default)
  const dispatch = useDispatch()
  /** @type (sortBy: string, sortDirection: "desc" | "asc",) => void */
  const handleSort = (sortBy, sortDirection) => {
    dispatch(actions.setSortFormTable({ sortBy, sortDirection }, { name: name }))
  }
  /** @type (e: React.ChangeEvent<HTMLInputElement>) => void */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (disabledDelete) {
        const arr = []
        formTable.allIds.forEach(key => {
          const obj = formTable.byId[key]
          const addDel = disabledDelete(obj)
          if (!addDel) arr.push(key)
        })
        setArrDelete(arr)
        return;
      }
      setArrDelete(formTable.allIds)
      return;
    }
    setArrDelete([])
  };
  return <CustomTable2
    idName={idName}
    classes={classesPage}
    tableActions={{
      onDeleteRow: (deleteItem) ? handleDeleteOne : undefined,
      onClickRow: (click) ? (id) => `${history.location.pathname}/${id}` : undefined,
      onClickFunction: onClickFunction,
      disabledDelete: disabledDelete,
      addToArrDelete: addToArrDelete,
      handleSelectAllClick: handleSelectAllClick,
      setSort: handleSort,
      ...tableActions
    }}
    tableHead={columns}
    tableData={formTable}
    history={history}
    search={{ limit: search.limit, page: Number(search.offset) / Number(search.limit) }}
    deleteMulti={deleteMulti}
    arrDeleteMulti={arrDelete}
    handleChangePage={handleChangePage}
    handleChangeRowsPerPage={handleChangeRowsPerPage}
    arrHidden={arrHidden}
    goBack={goBack}
    firstHistoryLength={firstHistoryLength}
    {...tableProps}
  />
}