import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/form';
import * as api from '../../../api/list'
import validateProps from '../../../functions/validateProps'
import { enqueueSnackbar } from '../../../reducers/notifier'
import SimpleAdd from '../../../components/SimpleAdd'

export default function EquipmentAdd(props) {
  const formName = 'EquipmentAdd'
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.formInitialize({ name: '', idStorageUnit: '', idType: '' }, { name: formName }))
    return () => {
      dispatch(actions.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const arrProps = [
    {
      field: 'name', type: 'text', label: 'Наименование',
      validator: [value => value !== ''], errorText: 'Поле обязательно',
    },
    {
      field: 'idStorageUnit', label: 'Единица хранения', type: 'autoLoadCreate',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        path: api.getStorageUnit.path,
        createAPI: api.addStorageUnit,
        createBody: 'name',
        meta: {
          field: 'storageUnit',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    {
      field: 'idType', label: 'Тип', type: 'autoLoadCreate',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: {
        createAPI: api.typequipment.addOne,
        createBody: 'name',
        path: api.typequipment.getList.path,
        meta: {
          field: 'typeE',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
  ]
  const handleSave = (e) => {
    e.preventDefault();
    const isError = validateProps(arrProps, values)
    dispatch(actions.formChange(isError.validate, { field: 'errorValidate', name: formName }))
    if (isError.check) {
      dispatch(enqueueSnackbar({
        message: `некорректные поля`,
        options: { variant: 'error' }
      }))
      return;
    }
    dispatch(actions.formSubmitAPI({
      props: {
        name: values.name,
        idStorageUnit: values.idStorageUnit,
        idType: values.idType
      },
      url: api.addEquipment.path,
      method: api.addEquipment.type,
      history: props.history
    }, formName))
  };
  return (
    <div className='App-paper' >
      <form autoComplete="off" onSubmit={handleSave}>
        <div style={{ padding: '16px' }}>
          <SimpleAdd formName={formName} arr={arrProps} title='Добавление оборудование' />
        </div>
        <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
        <Button type="submit" color="primary">Сохранить</Button>
      </form>
    </div>
  );
}