import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, TableHead, TableRow, TableCell, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BuildIcon from '@material-ui/icons/Build';
import { formChange } from '../../../../reducers/form';
import PopperColumnType from './PopperColumnType'
import PopperColumnVisible from './PopperColumnVisible'
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  cell: {
    minWidth: 120,
    backgroundColor: theme.palette.grey[300],
    fontWeight: 700,
    cursor: 'pointer'
  },
  row: {
    backgroundColor: theme.palette.grey[400]
  },
  titleOK: {
    color: theme.palette.success.main
  }
}));

export default function TableHeader({ formName }) {
  const classes = useStyles();
  const column = useSelector(state => state.form[formName]?.values.column)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectKey, setSelectKey] = useState(null)
  const handleClick = (key, currentTarget) => {
    setSelectKey(key)
    setAnchorEl(currentTarget)
  };
  return <TableHead>
    <PopperColumnType anchorEl={anchorEl} onClose={() => setAnchorEl(null)} column={column} formName={formName} selectKey={selectKey} />
    <TableRow className={classes.row}>
      <CheckboxCell formName={formName} classes={classes} />
      {Object
        .keys(column)
        .filter(key => column[key].visible)
        .map(key => {
          const obj = column[key];
          return <TableCell
            key={key}
            onClick={(e) => { handleClick(key, e.currentTarget) }}
            align='center'
            className={classNames(classes.cell, {
              [classes.titleOK]: obj.select !== '',
            })}
          >
            {(obj.select === '') ? key : obj.selectName}
          </TableCell>
        })}
    </TableRow>
  </TableHead>
}

function CheckboxCell({ formName, classes }) {
  const selectId = useSelector(state => state.form[formName]?.values.selectId || [])
  const allIds = useSelector(state => state.formTable[formName]?.allIds || [])
  const all = allIds.length === selectId.length
  const dispatch = useDispatch()
  const [anchorList, setAnchorList] = useState(null)
  const handleSelect = () => {
    if (all) {
      dispatch(formChange([], { field: 'selectId', name: formName }));
    }
    else {
      dispatch(formChange(allIds, { field: 'selectId', name: formName }));
    }
  }
  return <TableCell padding="checkbox" className={classes.cell} >
    <PopperColumnVisible anchorList={anchorList} onClose={() => setAnchorList(null)} formName={formName} />
    <Checkbox
      checked={selectId.length !== 0}
      onClick={handleSelect}
      indeterminate={allIds.length !== selectId.length && selectId.length !== 0}
    />
    <IconButton size='small' onClick={(e) => { setAnchorList(e.currentTarget) }}>
      <BuildIcon fontSize='small' />
    </IconButton>
  </TableCell>
}