import React from 'react';
import can from '../../../functions/can'
import { loadProvider, deleteProvider } from '../../../api/list'
import PageList from '../../../components/PageListAPI'

export default function Provider(props) {
  return (
    <div className='App-paper'>
      <PageList
        name='provider'
        title='Поставщики материалов и оборудования'
        columns={[{ id: 'inn', name: 'ИНН', width:'15%' }, { id: 'name', name: 'Название' }]}
        createItem={can('provider:create')}
        deleteItem={can('provider:delete')}
        loadAPI={{
          request: loadProvider,
        }}
        deleteAPI={deleteProvider}
        {...props}
      />
    </div>
  )
}