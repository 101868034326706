import React, { useState, useEffect } from 'react';
import {
  Button, Checkbox, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, FormControlLabel,
  Grid, IconButton, MenuItem, Select, Tooltip, TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompliteWithLoad from '../../../components/AutocompliteWithLoad'
import { formSubmitAPI, formChange } from '../../../reducers/form';
import { deleteRowsFormTable } from '../../../reducers/formTable';
import { enqueueSnackbar } from '../../../reducers/notifier'
import { editFieldTable } from '../../../reducers/formTable';
import { makeStyles } from '@material-ui/core/styles';
import { getFacilityOurProject } from '../../../api/facility'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
  }
}));
const formProjectAuto = 'projects'
export default function Header({ formName, handleBack }) {
  const classes = useStyles()
  const [type, setType] = useState('material')
  const [createGroup, setCreateGroup] = useState(false)
  const form = useSelector(state => state.form[formName] || state.form.default)
  const idFacility = useSelector(state => state.form[formName]?.values.idFacility)
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { selectId, multiSelect, countSelect } = form.values
  const { byId } = formTable
  const dispatch = useDispatch()
  const formChangeForm = (value, field) => dispatch(formChange(value, { field: field, name: formName }))
  const handleChangeCountSelect = (e) => formChangeForm(e.target.value, 'countSelect')
  const handleChangeMultiSelect = () => formChangeForm(!multiSelect, 'multiSelect')
  const handleDeleteRow = () => {
    dispatch(deleteRowsFormTable(selectId, { name: formName }));
    formChangeForm([], 'selectId')
  }
  const handleUniteRow = () => {
    let unitObj = {}
    const arr = selectId.map(e => Number(e.substring(2))).sort((a, b) => (a > b) ? 1 : -1).map(e=>'id' + e)
    arr.forEach(e => {
      const obj = byId[e]
      Object.keys(obj).forEach(key => {
        if (unitObj[key]) {
          unitObj[key] += obj[key] + ' '
        }
        else {
          unitObj[key] = obj[key] + ' '
        }
      })
    });
    const [first, ...other] = arr
    Object.keys(unitObj).forEach(key => {
      dispatch(editFieldTable(unitObj[key], { field: key, id: first, name: formName }))
    })
    formChangeForm([], 'selectId')
    dispatch(deleteRowsFormTable(other, { name: formName }))
  }
  return <div style={{ marginBottom: '16px' }}>
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end" spacing={1}>
        <Grid item><Button onClick={handleBack} color='primary'>&lt;Выбор файла</Button></Grid>
        <Grid item><ButtonLoadRow createGroup={createGroup} type={type} formName={formName} /></Grid>
        <Grid item xs={6}>
          <AutoCompliteWithLoad
            classes={classes}
            field='idProject'
            name='Проект'
            path={getFacilityOurProject.path({ id: idFacility })}
            meta={{
              field: formProjectAuto,
              value: 'id',
              labelFunc: obj => obj.name,
              filter: arr=>arr.filter(e=>!e.isHidden)
            }}
            formName={formName}
          />
        </Grid>
      </Grid>
      <Grid item>
        <ButtonDivider />
        Тип:
        <Select value={type} onChange={(e) => setType(e.target.value)}>
          <MenuItem value='material'>материалы</MenuItem>
          <MenuItem value='work'>работа</MenuItem>
        </Select>
        <ButtonDivider />
        <Button color={multiSelect ? 'secondary' : 'primary'} onClick={() => handleChangeMultiSelect()}>Режим выделения</Button>
        <Select
          value={countSelect}
          onChange={handleChangeCountSelect}
          disabled={!multiSelect}
        >
          {[1, 10, 20, 30].map(key => <MenuItem key={key} value={key}>{key}</MenuItem>)}
        </Select>
        <ButtonDivider />
        <ButtonSimple title="Объединить 2 стоки" ariaLabel="merge row" Icon={VerticalAlignCenterIcon} handleClick={handleUniteRow} disabled={selectId.length === 0} />
        <ButtonSimple title="Удалить строки" ariaLabel="delete row" Icon={DeleteIcon} handleClick={handleDeleteRow} disabled={selectId.length === 0} />
        <ButtonDivider />
        <FormControlLabel
          control={
            <Checkbox
              checked={createGroup}
              onChange={() => setCreateGroup(old => !old)}
              color="primary"
            />
          }
          label="Создать группу"
        />
      </Grid>
    </Grid>

  </div>
}

function ButtonDivider() {
  const style = {
    borderLeft: '2px solid #9391a22e',
    height: '32px',
    verticalAlign: 'middle',
    display: 'inline-block',
    margin: 5
  }
  return <div style={style} />
}

function ButtonSimple({ title, ariaLabel, handleClick, Icon, disabled }) {
  return <Tooltip title={title} aria-label={ariaLabel}>
    <IconButton color='primary' onClick={handleClick} disabled={disabled}><Icon /></IconButton>
  </Tooltip>
}

function ButtonLoadRow({ createGroup, formName, type }) {
  const [openDialog, setOpenDialog] = useState(false)
  const [groupName, setGroupName] = useState('')
  const dispatch = useDispatch()
  const errSnackbar = (msg) => dispatch(enqueueSnackbar({ message: msg, options: { variant: 'error' } }))
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { selectId, idProject, column, estimate, consist } = form.values
  const formTable = useSelector(state => state.formTable[formName] || state.formTable.default)
  const { byId } = formTable
  const handleLoad = () => {
    if (!idProject) {
      errSnackbar('Проект не выбран')
      return;
    }
    if (selectId.length === 0) {
      errSnackbar('Материалы не выбраны')
      return;
    }
    let columnNew = {}
    const columnName = []
    for (const [key, value] of Object.entries(column)) {
      if (value.select !== '') {
        if (type === 'work' && (value.select === 'typeMark' || value.select === 'vendorCode')) continue;
        columnNew[key] = value.select
        columnName.push(value.select)
      }
    }
    if (['name', 'count', 'storageUnit'].filter(x => !columnName.includes(x)).length !== 0) {
      errSnackbar('Не выбраны обязательные поля')
      return
    }
    let keyCount = ''
    for (const [key, value] of Object.entries(columnNew)) {
      if (value === 'count') keyCount = key;
    }
    let errCount = false
    const arr = selectId.map(key => {
      const obj = byId[key]
      const count = parseFloat(String(obj[keyCount]).replace(',', '.').replace(' ', ''))
      if (Number.isNaN(Number(count))) {
        errCount = true
      }
      return { ...byId[key], [keyCount]: count || 0 }
    })
    if (errCount) {
      errSnackbar('Колонка количество имеет нечисловые поля')
      return;
    }
    dispatch(formSubmitAPI({
      props: {
        idProject: idProject,
        arr: arr,
        column: columnNew,
        estimate: estimate,
        consist: consist,
        createGroup: createGroup,
        groupName: groupName
      },
      url: (type === 'material') ? '/materials/many' : `/projects/${idProject}/work/load`,
      method: 'POST',
      endFunc: () => {
        dispatch(deleteRowsFormTable(selectId, { name: formName }));
        dispatch(formChange([], { field: 'selectId', name: formName }));
        handleCloseDialog()
      }
    }, formName))
  }
  const handleButtonLoad = () => {
    if (createGroup) setOpenDialog(true); else handleLoad()
  }
  const handleCloseDialog = () => {
    setOpenDialog(false)
    setGroupName('')
  }
  return <>
    <Button color='primary' onClick={handleButtonLoad}>Загрузить</Button>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Группа</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Введите наименование группы в смете
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Отмена
        </Button>
        <Button onClick={handleLoad} color="primary">
          Загрузить
        </Button>
      </DialogActions>
    </Dialog>
  </>
}