import React, { useEffect, useState } from 'react';
import {
  Typography, Checkbox,
  FormControlLabel, Button,
  CircularProgress, Tooltip, Grid,
  Table, TableHead, TableBody, TableRow, TableCell
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../reducers/materialLoad';
import * as actions2 from '../../reducers/form';
import { loadGrand } from '../../api/grand'
import SimpleAdd from '../../components/SimpleAdd'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px',
  },
  main: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));
const formName = 'loadGrand'
const arrConsist = [{ value: 'add', label: 'Добавить' }, { value: 'change', label: 'Изменить' }]
const paramsAdd = [
  {
    field: 'consist', type: 'autoArr', label: 'Если позиция уже есть в проекте',
    validator: [value => !!value], errorText: 'Поле обязательно',
    textProps: { arr: arrConsist, defaultValue: 'add', selectProps: { isDisabled: true } },
  },
  { field: 'coefficient', type: 'text', label: 'Ценовой коэффициент', textProps: { type: 'number' } },
  {
    field: 'estimate', type: 'component',
    textProps: {
      component: (values, handleChange) => {
        return <FormControlLabel
          control={
            <Checkbox
              checked={values || false}
              onChange={(e) => handleChange(e.target.checked, 'estimate')}
              name="estimate"
              color="primary"
            />
          }
          label="Материалы заказчика"
        />
      }
    },
  },
  {
    field: 'pure', type: 'component',
    textProps: {
      component: (values, handleChange) => {
        return <FormControlLabel
          control={
            <Checkbox
              checked={values || false}
              onChange={(e) => handleChange(e.target.checked, 'pure')}
              name="pure"
              color="primary"
            />
          }
          label="Форматировать единицы измерения"
        />
      }
    },
  },
  {
    field: 'saveInEstimate', type: 'component',
    textProps: {
      component: (values, handleChange) => {
        return <FormControlLabel
          control={
            <Checkbox
              checked={values || false}
              onChange={(e) => handleChange(e.target.checked, 'saveInEstimate')}
              name="saveInEstimate"
              color="primary"
            />
          }
          label="Сохранить группировку"
        />
      }
    },
  },
]
export default function LoadGrand(props) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [tableResource, setTableResource] = useState({ chapters: [], positions: [] })
  useEffect(() => {
    dispatch(actions2.formInitialize({
      consist: arrConsist[0].value,
      fileShort: '',
      file: '',
      fileId: '',
      estimate: false,
      pure: false,
      saveInEstimate: true,
      coefficient: 1.2,
      valueconsist: arrConsist[0],
      errorValidate: {
        consist: false
      },
    }, { name: formName }))
    return () => {
      dispatch(actions2.formDestroy({ name: formName }))
    }
  }, [dispatch])
  const [loading, setLoading] = useState(false)
  const handleLoadFile = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    let shortName = e.target.files[0].name
    if (shortName.length > 38) {
      shortName = e.target.files[0].name.slice(0, 17) + '...' + e.target.files[0].name.slice(-18)
    }
    dispatch(actions.loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          dispatch(actions2.formChangeAsObj({ file: e.target.files[0].name, fileShort: shortName, fileId: res.id }, { name: formName }))
        }
        setLoading(false)
      })
      .catch()
  }
  const form = useSelector(state => state.form[formName] || state.form.default)
  const { values } = form
  const handleParseFile = (save) => {
    const params = {
      idProject: props.match.params.id,
      estimate: values.estimate || false,
      pure: values.pure || false,
      consist: values.consist || 'change',
      idFiles: values.fileId || 249,
      coefficient: values.coefficient,
      save,
      saveInEstimate: values.saveInEstimate,
    }
    dispatch(actions2.formSubmitAPI({
      props: params,
      url: loadGrand.path,
      method: loadGrand.type,
      endFunc: (res) => {
        if (!save) {
          setTableResource({
            chapters: res.chapters, positions: res.positions
          })
        }
      }
      // history: props.history
    }, formName))
  }
  return <div className='App-paper'>
    <div className={classes.formControl}>
      <input
        className={classes.input}
        id="contained-button-file"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleLoadFile}
      />
      <label htmlFor="contained-button-file">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item >
            <Button variant='contained' component="span" size="small">Файл</Button>
          </Grid>
          <Grid item >
            <Tooltip title={values.file}><Typography>{values.fileShort}</Typography></Tooltip>
          </Grid>
          <Grid item >
            {loading && <CircularProgress size={20} />}
          </Grid>
        </Grid>
      </label>
    </div>
    <SimpleAdd
      arr={paramsAdd}
      formName={formName}
    />
    <Button color="primary" onClick={() => props.history.goBack()}>Назад </Button>
    <Button
      color="primary"
      onClick={() => handleParseFile(false)}
      className={classes.button}
    >
      Просмотр файла
    </Button>
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleParseFile(true)}
      className={classes.button}
    >
      Загрузить
    </Button>
    {tableResource.chapters.length > 0 && <TablePositions chapters={tableResource.chapters} positions={tableResource.positions} />}
  </div>
}

const columns = [
  { value: 'number', label: '№ п/п', align: 'center' },
  { value: 'isMaterial', label: 'Тип', align: 'center' },
  { value: 'name', label: 'Наименование работ и затрат', align: 'left' },
  { value: 'storageUnitOriginal', label: 'Единица измерения ориг.', align: 'center' },
  { value: 'countOriginal', label: 'Количество ориг.', align: 'center' },
  { value: 'priceOriginal', label: 'Сметная стоимость ориг.', align: 'right' },
  { value: 'storageUnit', label: 'Единица измерения', align: 'center' },
  { value: 'count', label: 'Количество', align: 'center' },
  { value: 'price', label: 'Сметная стоимость', align: 'right' },
]

function TablePositions({ chapters, positions }) {
  return <Table size='small'>
    <TableHead>
      <TableRow>
        {columns.map(e => {
          return <TableCell key={e.value} align='center'>{e.label}</TableCell>
        })}
      </TableRow>
    </TableHead>
    <TableBody>
      {chapters.map(chapter => {
        return <>
          <TableRow><TableCell colSpan={columns.length}>{chapter.name}</TableCell></TableRow>
          {chapter.positions.map(positionKey => {
            const obj = positions[positionKey]
            return <TableRow key={positionKey}>
              {columns.map(e => {
                if (e.value === 'isMaterial') return <TableCell align={e.align}>{obj.isMaterial ? 'М' : 'Р'}</TableCell>
                return <TableCell align={e.align}>{obj[e.value]}</TableCell>
              })}
            </TableRow>
          }
          )}
        </>
      })}
    </TableBody>
  </Table>
}