import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { formInitialize, formDestroy } from '../../../reducers/form';
import Header from './Header'
import TableMain from './MaterialTable/TableMain'

const dialogName = 'dialogName'

export default function TableMaterial({ handleBack, formName }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(formInitialize({
      anchorEl: null,
      editKey: '',
      editValue: '',
      editId: ''
    }, { name: dialogName }))
    return () => {
      dispatch(formDestroy({ name: dialogName }))
    }
  }, [dispatch])
  return (<>
    <Header formName={formName} handleBack={handleBack} />
    <TableMain formName={formName} />
  </>);
}