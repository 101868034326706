import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Checkbox, FormControlLabel, Button, Grid, Tooltip, CircularProgress, List,
  ListItem, ListItemText, IconButton, ListItemSecondaryAction
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../reducers/materialLoad';
import * as actions2 from '../../../reducers/form';
import { fetchDispath } from '../../../functions/fetch'
import SimpleAdd from '../../../components/SimpleAdd'
import { addFileParse, getFileParse, deleteFileParse } from '../../../api/list'
import { parseMaterialFile } from '../../../api/material'
import { setFormTable } from '../../../reducers/formTable';
import { useHistory } from 'react-router-dom';
import { getFacilityOur } from '../../../api/facility'

import moment from 'moment'
import 'moment/locale/ru';
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  input: {
    display: 'none',
  },
  edt: {
    padding: '1px', /* Поля вокруг текста */
  },
  main: {
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 240,
    marginTop: 5,
    marginBottom: 3,
    width: '50%'
  },
}));
export const arrConsist = [{ value: 'add', label: 'Добавить' }, { value: 'change', label: 'Изменить' }]

export default function SelectFile({ formName, handleNext }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [loadFileP, setLoadFileP] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')

  const fileId = useSelector(state => state.form[formName]?.values.fileId)
  const end = useSelector(state => state.form[formName]?.values.end)
  const start = useSelector(state => state.form[formName]?.values.start)
  const listName = useSelector(state => state.form[formName]?.values.listName)
  const file = useSelector(state => state.form[formName]?.values.file)
  const fileShort = useSelector(state => state.form[formName]?.values.fileShort)
  const idFacility = useSelector(state => state.form[formName]?.values.idFacility)
  const listArr = useSelector(state => state.form[formName]?.values.listArr || [])
  
  const arrProps = [
    {
      field: 'idFacility', type: 'autoLoad', label: 'Объект',
      textProps: {
        path: getFacilityOur.path + '?template=0',
        meta: {
          field: 'facility',
          value: 'id',
          labelFunc: (obj) => obj.name
        }
      }
    },
    { field: 'start', type: 'text', label: 'Начало', props: { autoComplete: 'off', type: 'number' } },
    { field: 'end', type: 'text', label: 'Окончание', props: { autoComplete: 'off', type: 'number' } },
    {
      field: 'consist', type: 'autoArr', label: 'Если есть',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: arrConsist, defaultValue: 'add', },
    },
    {
      field: 'listName', type: 'autoArr', label: 'Лист',
      validator: [value => !!value], errorText: 'Поле обязательно',
      textProps: { arr: listArr },
    },
    {
      field: 'estimate', type: 'component',
      textProps: {
        component: (values, handleChange) => {
          return <FormControlLabel
            control={
              <Checkbox
                checked={values || false}
                onChange={(e) => handleChange(e.target.checked, 'estimate')}
                name="estimate"
                color="primary"
              />
            }
            label="Материалы заказчика"
          />
        }
      },
    },
  ]
  const loadFilePrice = useCallback(() => {
    return dispatch(fetchDispath({
      progress: true,
      request: getFileParse,
    }))
      .then(res => {
        let filePByIds = {}
        const filePAllIds = res.get.map(e => {
          let id = 'id' + e.id
          filePByIds[id] = e;
          return id
        })
        dispatch(actions2.formChangeAsObj({ filePByIds, filePAllIds }, { name: formName }))
      }).catch(err => console.log(err))
  }, [dispatch, formName])

  useEffect(() => {
    if (loadFileP) return;
    loadFilePrice()
    setLoadFileP(true)
  }, [loadFilePrice, loadFileP])

  const _handleImageChange = (e) => {
    e.preventDefault();
    if (typeof e.target.files[0] === 'undefined' || e.target.files[0].length === 0) return
    setLoading(true)
    const fileName = e.target.files[0].name
    dispatch(actions2.formChangeAsObj({ file: fileName, fileShort: fileNameShorter(fileName) }, { name: formName }))
    dispatch(actions.loadFileAPI({ file: e.target.files[0], path: `/files/add` }))
      .then(res => {
        if (res.success) {
          const fileId = res.id
          dispatch(fetchDispath({
            querty: {
              id: fileId,
            },
            progress: true,
            request: { path: '/materials/parse/info', type: 'GET' },
          }))
            .then(res => {
              const listArr = res.get.map(e => ({ value: e, label: e }))
              dispatch(actions2.formChange(listArr, { field: 'listArr', name: formName }))
              setSelectedFile(fileId + '')
              dispatch(fetchDispath({
                body: {
                  idFile: fileId,
                  name: fileName,
                  lists: res.get
                },
                progress: true,
                request: addFileParse,
              }))
                .then(res => {
                  loadFilePrice()
                }).catch(err => console.log(err))
            }).catch(err => console.log(err))
          dispatch(actions2.formChange(fileId, { field: 'fileId', name: formName }))
        }
        setLoading(false)
      })
      .catch()
  }
  const handleParseMaterial = () => {
    dispatch(fetchDispath({
      querty: {
        id: fileId,
        end: end,
        start: start,
        list: listName
      },
      progress: true,
      request: parseMaterialFile,
    }))
      .then(res => {
        let columnObj = {}
        let column = {}
        let byId = {}
        let idCell = 0
        let allIds = res.map(row => {
          let id = 'id' + idCell
          idCell++
          byId[id] = row
          Object.keys(row).forEach(value => {
            if (columnObj[value]) {
              columnObj[value].count++
            }
            else columnObj[value] = { count: 1, visible: true, select: '', selectName: '' }
          })
          return id;
        })
        Object.keys(columnObj).sort((a, b) => {
          if (a.length < b.length) return -1
          if (a.length > b.length) return 1
          if (a.length === b.length && a < b) return -1
          if (a.length === b.length && a > b) return 1
          return 0
        }).forEach(value => { column[value] = columnObj[value] })
        dispatch(setFormTable({ allIds, byId }, { name: formName }))
        dispatch(actions2.formChangeAsObj({ column: column }, { name: formName }))
        handleNext(1)
      }).catch(err => console.log(err))
  }
  return (<>
    <Grid container
      direction='row'
      justifyContent="flex-start"
      alignItems="flex-start">
      <Grid item xs={6}>
        <Typography variant='h6'>Выбор файла</Typography>
        <div className={classes.formControl}>
          <input
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={(e) => _handleImageChange(e)}
          />
          <label htmlFor="contained-button-file">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item >
                <Button variant='contained' component="span" size="small">Файл</Button>
              </Grid>
              <Grid item >
                <Tooltip title={file || ''}><Typography>{fileShort}</Typography></Tooltip>
              </Grid>
              <Grid item >
                {loading && <CircularProgress size={20} />}
              </Grid>
            </Grid>
          </label>
        </div>
        <div style={{ padding: '16px' }}>
          <SimpleAdd
            arr={arrProps}
            formName={formName}
            gridProps={{ xs: 12 }}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <OldFileList selectedFile={selectedFile} formName={formName} loadFilePrice={loadFilePrice} />
      </Grid>
    </Grid>
    <Button onClick={() => history.goBack()}>Назад</Button>
    <Button
      disabled={fileId === 0 || !idFacility}
      variant="contained"
      color="primary"
      onClick={handleParseMaterial}
    >Далее</Button>
  </>);
}

function fileNameShorter(fileName) {
  if (fileName.length > 38) {
    return fileName.slice(0, 17) + '...' + fileName.slice(-18)
  }
  else return fileName
}

function OldFileList({ selectedFile, formName, loadFilePrice }) {
  const dispatch = useDispatch()
  const filePAllIds = useSelector(state => state.form[formName]?.values.filePAllIds || [])
  const filePByIds = useSelector(state => state.form[formName]?.values.filePByIds || {})
  const handleSelectFile = (obj) => {
    dispatch(actions2.formChangeAsObj({ file: obj.name, fileShort: fileNameShorter(obj.name), fileId: obj.idFile }, { name: formName }))
  }
  const handleDeleteFile = (id) => {
    dispatch(fetchDispath({
      progress: true,
      request: { path: deleteFileParse.path({ id: id }), type: deleteFileParse.type },
    }))
      .then(res => {
        loadFilePrice()
      }).catch(err => console.log(err))
  }
  return <>
    <Typography variant='h6'>Старые файлы</Typography>
    <List>
      {filePAllIds.map(key => {
        const obj = filePByIds[key]
        return <ListItem
          key={key}
          button={true}
          onClick={() => handleSelectFile(obj)}
          selected={selectedFile === obj.id}
        >
          <ListItemText primary={obj.name} secondary={moment(obj.date).format('LLL')} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFile(obj.id)}><DeleteIcon /></IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      })}
    </List>
  </>
}